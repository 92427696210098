<template>
  <b-card
    no-body
    header=" "
    header-bg-variant="light-primary"
  >
    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <v-select
          id="postStatus"
          ref="postStatus"
          v-model="pointData.post_status"
          :options="optionPostStatuses"
          :reduce="title => title.slug"
          label="title"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :clearable="false"
        >
          <template #option="{ title, icon }">
            <feather-icon
              v-if="icon"
              :icon="icon"
              size="16"
              class="align-middle mr-50"
            />
            <span> {{ $t(title) }}</span>
          </template>
          <template #selected-option="{ title, icon }">
            <feather-icon
              v-if="icon"
              :icon="icon"
              size="16"
              class="align-middle mr-50"
            />
            <span> {{ $t(title) }}</span>
          </template>
        </v-select>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          block
        >
          <span
            v-if="submitting"
            class="align-middle"
          >
            <b-spinner small />
            {{ $t('Loading...') }}
          </span>
          <span
            v-else
            class="align-middle"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />{{ $t('Submit') }}</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BSpinner,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { postStatuses } from '@/mixins/options'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
  },
  mixins: [postStatuses],
  props: {
    data: {
      type: Object,
      required: true,
    },
    submitting: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      pointData: this.data,
      optionPostStatuses: [],
    }
  },

  mounted() {
    this.$watch('data', (newValue, oldValue) => {
      this.pointData = newValue
    })
  },
  created() {
    this.getOptionPostStatuses()
  },
  methods: {
    getOptionPostStatuses() {
      this.optionPostStatuses = this.postStatuses.filter(status => status.slug !== 'incomplete' && status.slug !== 'closed')
    },
    deleteRequest(point) {
      store.dispatch('point/deleteServiceRequest', { id: point.id }).then(response => {
        if ([200, 201, 'success'].includes(response.status)) {
          this.showToast(response, 'Request')
          // GlobalEventEmitter.$emit('requestDeleted', point)
          router.push({ name: 'apps-service-my-requests' })
        }
      })
    },
  },
}
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
