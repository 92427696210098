var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"CompassIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" "+_vm._s(_vm.$t('Coordinates'))+" ")])],1)])],1),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"location"}},[_c('br'),_c('l-map',{ref:"map",staticStyle:{"height":"240px","width":"100%"},attrs:{"center":[
              _vm.location.position.lat || _vm.defaultLocation.lat,
              _vm.location.position.lng || _vm.defaultLocation.lng
            ],"options":_vm.map.options},on:{"dblclick":_vm.onMapClick}},[_c('l-tile-layer',{attrs:{"url":_vm.tileProvider.url}}),(_vm.location.position.lat && _vm.location.position.lng)?_c('l-marker',{attrs:{"visible":"","draggable":true,"lat-lng":_vm.location.position},on:{"update:latLng":function($event){return _vm.$set(_vm.location, "position", $event)},"update:lat-lng":function($event){return _vm.$set(_vm.location, "position", $event)},"dragstart":function($event){_vm.dragging = true},"dragend":function($event){return _vm.coordinatesAreChanged()}}}):_vm._e(),(_vm.location.radius)?_c('l-circle',{attrs:{"lat-lng":_vm.location.position,"radius":_vm.location.radius,"color":"red"},on:{"update:latLng":function($event){return _vm.$set(_vm.location, "position", $event)},"update:lat-lng":function($event){return _vm.$set(_vm.location, "position", $event)}}}):_vm._e()],1),_c('b-card-text',[_c('b-form',[_c('b-form-group',{attrs:{"label":_vm.$t('Address'),"label-for":"address"}},[_c('b-form-input',{ref:"autocomplete",attrs:{"id":"address","disabled":""},model:{value:(_vm.location.formatted_address),callback:function ($$v) {_vm.$set(_vm.location, "formatted_address", $$v)},expression:"location.formatted_address"}})],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Country'),"label-for":"country"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"capitalize-first-letter",rawName:"v-capitalize-first-letter"}],attrs:{"id":"country","trim":""},model:{value:(_vm.location.address.country),callback:function ($$v) {_vm.$set(_vm.location.address, "country", $$v)},expression:"location.address.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"6","xl":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('State'),"label-for":"state"}},[_c('validation-provider',{attrs:{"name":_vm.$t('State'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"capitalize-first-letter",rawName:"v-capitalize-first-letter"}],attrs:{"id":"state","trim":""},model:{value:(_vm.location.address.state),callback:function ($$v) {_vm.$set(_vm.location.address, "state", $$v)},expression:"location.address.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12","xl":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Locality'),"label-for":"locality"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Locality'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"capitalize-first-letter",rawName:"v-capitalize-first-letter"}],attrs:{"id":"locality","trim":""},model:{value:(_vm.location.address.locality),callback:function ($$v) {_vm.$set(_vm.location.address, "locality", $$v)},expression:"location.address.locality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"8","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Street'),"label-for":"road"}},[_c('b-form-input',{directives:[{name:"capitalize-first-letter",rawName:"v-capitalize-first-letter"}],attrs:{"id":"road","trim":""},model:{value:(_vm.location.address.road),callback:function ($$v) {_vm.$set(_vm.location.address, "road", $$v)},expression:"location.address.road"}})],1)],1),_c('b-col',{attrs:{"cols":"6","xl":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('House'),"label-for":"house_number"}},[_c('b-form-input',{attrs:{"id":"house_number","disabled":!_vm.location.address.road,"trim":""},model:{value:(_vm.location.address.house_number),callback:function ($$v) {_vm.$set(_vm.location.address, "house_number", $$v)},expression:"location.address.house_number"}})],1)],1),_c('b-col',{attrs:{"cols":"6","xl":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Apt.'),"label-for":"apartment_number"}},[_c('b-form-input',{attrs:{"id":"apartment_number","disabled":!_vm.location.address.house_number,"trim":""},model:{value:(_vm.location.address.apartment_number),callback:function ($$v) {_vm.$set(_vm.location.address, "apartment_number", $$v)},expression:"location.address.apartment_number"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Latitude'),"label-for":"lat"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Latitude'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lat","disabled":true},model:{value:(_vm.location.position.lat),callback:function ($$v) {_vm.$set(_vm.location.position, "lat", $$v)},expression:"location.position.lat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Longitude'),"label-for":"lng"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Longitude'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lng","disabled":true},model:{value:(_vm.location.position.lng),callback:function ($$v) {_vm.$set(_vm.location.position, "lng", $$v)},expression:"location.position.lng"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{staticClass:"mb-0",attrs:{"variant":"danger","show":!_vm.location.address_confirmed}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Address is unconfirmed'))+" ")],1),_c('validation-provider',{attrs:{"name":_vm.$t('Address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-none",attrs:{"v-model":_vm.location.address_confirmed,"disabled":true}})]}}])})],1),_c('b-alert',{staticClass:"mb-0",attrs:{"variant":"success","show":_vm.location.address_confirmed}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Address is confirmed'))+" ")],1)]),_c('b-alert',{staticClass:"mb-0",attrs:{"variant":"danger","show":!_vm.coordinatesTaken}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Coordinates have not been updated'))+" ")],1),_c('validation-provider',{attrs:{"name":_vm.$t('Coordinates'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"d-none",attrs:{"v-model":_vm.coordinatesTaken,"disabled":true}})]}}])})],1),_c('b-alert',{staticClass:"mb-0",attrs:{"variant":"success","show":_vm.coordinatesTaken}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Coordinates have been updated'))+" ")],1)]),(_vm.error)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1),_c('b-col',{staticClass:"text-right",attrs:{"sm":"12","xl":"6"}},[(_vm.addressChanged && !_vm.error && !_vm.coordinatesTaken)?_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.fetchLocationBySearchQuery(_vm.location.address, true)}}},[_vm._v(" "+_vm._s(_vm.$t('Update Coordinates'))+" ")]):_vm._e(),(_vm.coordinatesTaken && !_vm.location.address_confirmed)?_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.confirmAddress('customer')}}},[_vm._v(" "+_vm._s(_vm.$t('Confirm Address'))+" ")]):_vm._e()],1)],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }